<template>
	<div v-if="user" class="profile">
		<Transition name="fade-slide-up" appear>
			<div class="top">
				<div class="user-info">
					<div class="avatar-container">
						<Avatar :user="user" :size="100" />
					</div>
					<div class="info">
						<h2>{{ user.display_name }}</h2>
						<div class="meta">
							<Score :override="score" />

							<div class="completed-quizzes">
								<Icon name="checkmark-circle-2" class="green" width="20" />
								<span
									>{{ completedQuizzes }}
									{{
										completedQuizzes === 1
											? translate('quizCompleted')
											: translate('quizzesCompleted')
									}}</span
								>
							</div>

							<div class="created-quizzes">
								<Icon name="question-mark-circle" class="purple" width="20" />
								<span
									>{{ createdQuizzes }}
									{{
										createdQuizzes === 1 ? translate('quizCreated') : translate('quizzesCreated')
									}}</span
								>
							</div>
						</div>
					</div>
				</div>

				<div v-if="myProfile" class="actions">
					<button class="secondary icon-right small" @click="$router.push('/settings')">
						{{ translate('settings') }}<Icon name="settings" width="20" />
					</button>
					<button class="secondary icon-right small" @click="signOut">
						{{ translate('signOut') }}<Icon name="log-out" width="20" />
					</button>
				</div>
			</div>
		</Transition>

		<Transition name="fade-slide-up" appear>
			<MyQuizzes v-if="myProfile" style="transition-delay: .3s;" />
		</Transition>

		<Transition name="fade-slide-up" appear>
			<QuizHistory :user="user" style="transition-delay: .6s;" />
		</Transition>
	</div>
</template>

<script>
import { auth, db } from '@/lib/supabase'
import { handleError } from '@/utils/utils'

import Avatar from '@/components/Avatar.vue'
import MyQuizzes from '@/components/MyQuizzes.vue'
import QuizHistory from '@/components/QuizHistory.vue'
import Score from '@/components/Score.vue'
import Icon from '../components/Icon.vue'

export default {
	components: {
		Avatar,
		MyQuizzes,
		QuizHistory,
		Score,
		Icon,
	},

	data() {
		return {
			user: null,
			completedQuizzes: 0,
			createdQuizzes: 0,
			score: 0,
			visiting: null,
		}
	},

	computed: {
		myProfile() {
			return this.user.id === auth.user().id
		},

		environment() {
			return this.$store.getters.environment
		},
	},

	async beforeMount() {
		const username = this.$route.params.username

		if (username) {
			await this.getProfileMetadata(username)
		}

		if (this.environment !== 'production') {
			console.log({ user: this.user })
		}
	},

	methods: {
		signOut() {
			auth
				.signOut()
				.then(() => {
					this.$router.replace({ name: 'Home' })
				})
				.catch((error) => {
					handleError(error)
				})
		},

		async getProfileMetadata(username) {
			const { data, error } = await db
				.from('users')
				.select('*')
				.eq('username', username.substring(1))

			if (data) {
				this.user = data[0]
				this.getCreatedQuizzes()
				this.getUserScore()
				this.getCompletedQuizzes()

				if (data.length === 0) {
					// TODO: Handle user not found error in GUI.
					console.log('User not found.')
				}
			} else {
				handleError(error)
			}
		},

		async getUserScore() {
			const { data: score, error } = await db.rpc('get_user_score', { user_id_param: this.user.id })

			if (error) handleError(error)
			else this.score = score
		},

		async getCompletedQuizzes() {
			const { data: completedQuizzes, error } = await db
				.from('attempts')
				.select('*')
				.eq('user_id', this.user.id)
				.not('completed_at', 'is', null)

			if (error) handleError(error)
			else this.completedQuizzes = completedQuizzes.length
		},

		async getCreatedQuizzes() {
			const { data, error } = await db
				.from('quizzes')
				.select('*')
				.eq('created_by', this.user.id)
				.is('public', true)

			if (data) {
				this.createdQuizzes = data.length
			} else {
				console.log('Error getting quizzes: ', error)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.profile {
	.top {
		align-items: flex-start;
		display: flex;

		@include mobile {
			flex-direction: column;
			justify-content: center;
		}
	}

	.user-info {
		align-items: center;
		display: flex;

		@include mobile {
			flex-direction: column;
			justify-content: center;
			width: 100%;
		}

		.info {
			margin-left: 25px;

			@include mobile {
				margin-left: 0;
				margin-top: 20px;
				text-align: center;
			}

			.meta {
				align-items: center;
				display: flex;
				margin-top: 15px;

				> div + div {
					margin-left: 15px;
				}

				> div:not(.score) {
					align-items: center;
					cursor: default;
					display: flex;

					.icon i {
						margin-right: 6px;
					}

					span {
						font-weight: 600;
					}
				}
			}
		}
	}

	.actions {
		margin-left: auto;

		@include mobile {
			margin-left: 0;
			margin-top: 25px;
			text-align: center;
			width: 100%;
		}

		button + button {
			margin-left: 15px;
		}
	}
}
</style>
