<template>
	<div class="quiz-history">
		<div class="top">
			<h3>{{ translate('quizHistory') }}</h3>
			<div class="sort-by select">
				<label for="sortBy">{{ translate('sortBy') }}:</label>
				<select id="sortBy" v-model="order" name="sortBy" class="no-border">
					<option value="date">{{ translate('date') }}</option>
					<option value="score">{{ translate('score') }}</option>
					<option value="time">{{ translate('time') }}</option>
				</select>
				<Icon name="chevron-down" width="18" />
			</div>
		</div>

		<div v-if="loaded" class="quizzes">
			<transition name="fade">
				<div v-if="showEmptyNotice" class="alert text-center extra-padding full-width">
					<p v-if="myProfile" v-html="translate('quizHistory.notice.noQuizzes.myProfile')"></p>
					<p v-else>{{ translate('quizHistory.notice.noQuizzes.otherProfile') }}</p>
				</div>
			</transition>

			<TransitionGroup appear @before-enter="beforeEnter" @enter="enter" @after-enter="afterEnter">
				<div
					v-for="(quiz, index) in quizzes"
					:key="quiz.id"
					class="quiz"
					:data-index="index"
					@click="$router.replace(`/q/${quiz.qid}`)">
					<p class="date">
						{{ quiz.completed_at ? formatDate(quiz.completed_at) : formatDate(quiz.started_at) }}
					</p>
					<p class="title">{{ quiz.title }}</p>
					<div v-if="quiz.completed_at">
						<div>
							<Time
								v-tippy
								:time="Date.parse(quiz.completed_at) - Date.parse(quiz.started_at)"
								:content="translate('time')" />
						</div>

						<div>
							<div v-tippy class="result" :content="translate('result')">
								<Icon name="checkmark-circle-2" class="green" width="18" />
								<span>{{ `${quiz.correct_guesses}/${quiz.questions}` }}</span>
							</div>
						</div>

						<div>
							<Score
								v-tippy
								:override="quiz.score"
								:clickable="true"
								:content="translate('score')" />
						</div>
					</div>
					<div v-if="!quiz.completed_at">
						<button class="small">{{ translate('continue') }}</button>
					</div>
				</div>
			</TransitionGroup>
		</div>

		<Loader v-if="!loaded" />
	</div>
</template>

<script>
import { auth, db } from '@/lib/supabase'
import { formatTime, handleError } from '@/utils/utils'
import gsap from 'gsap'

import Loader from '@/components/Loader.vue'
import Score from '@/components/Score.vue'
import Time from '@/components/Time.vue'
import Icon from './Icon.vue'

export default {
	components: {
		Loader,
		Score,
		Time,
		Icon,
	},

	props: {
		user: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			data: [],
			loaded: false,
			order: 'date',
			showEmptyNotice: false,
		}
	},

	computed: {
		quizzes() {
			switch (this.order) {
				case 'date':
					return this.sortByDate(this.data)

				case 'score':
					return this.sortByScore(this.data)

				case 'time':
					return this.sortByTime(this.data)

				default:
					return this.data
			}
		},

		myProfile() {
			return this.user.id === auth.user().uid
		},
	},

	async beforeMount() {
		this.data = await this.getHistory()

		if (this.data.length === 0) {
			this.showEmptyNotice = true
		}

		this.loaded = true
	},

	methods: {
		async getHistory() {
			const { data: quizzes, error } = await db.rpc('get_quiz_history', {
				user_id_param: this.user.id,
			})
			if (error) handleError(error)
			else return quizzes
		},

		sortByDate(array) {
			return array.sort((a, b) => {
				if (!a.completed_at && !b.completed_at) {
					return b.started_at - a.started_at
				} else if (!a.completed_at && b.completed_at) {
					return b.completed_at - a.started_at
				} else if (a.completed_at && !b.completed_at) {
					return b.started_at - a.completed_at
				} else {
					return b.completed_at - a.completed_at
				}
			})
		},

		sortByScore(array) {
			return array.sort((a, b) => b.score - a.score)
		},

		sortByTime(array) {
			return array.sort((a, b) => b.completed_at - b.started_at - (a.completed_at - a.started_at))
		},

		formatDate(timestamp) {
			return new Date(timestamp).toLocaleDateString(this.$store.getters.locale, {
				month: 'short',
				day: 'numeric',
			})
		},

		formatTime,

		beforeEnter(el) {
			el.style.opacity = 0
			el.style.transform = 'translateY(30px)'
		},

		enter(el, done) {
			gsap.to(el, {
				opacity: 1,
				y: 0,
				duration: 0.8,
				onComplete: done,
				delay: el.dataset.index * 0.1,
			})
		},

		afterEnter(el) {
			el.style.transition = '.25s ease'
		},
	},
}
</script>

<style lang="scss" scoped>
.quiz-history {
	margin-top: 50px;

	.top {
		align-items: center;
		display: flex;

		.select {
			align-items: center;
			display: flex;
			margin: 0 0 0 auto;
			opacity: 0.5;

			label {
				font-size: 14px;
				margin-bottom: 0;
				position: static;
				transform: none;
			}

			select {
				font-size: 14px;
				padding: 0 25px 1px 10px;
			}
		}
	}

	.loader {
		display: block;
		margin-top: 75px;
	}

	.quizzes {
		margin-top: 10px;

		.quiz {
			align-items: center;
			background: darken(white, 5%);
			border-radius: $border-radius;
			cursor: pointer;
			display: flex;
			padding: 20px 30px;
			transition: $transition;

			@include mobile {
				flex-wrap: wrap;
				padding: 10px 15px;

				.title {
					font-size: 16px;
					margin-top: 5px;
					padding-bottom: 5px;
					order: 4;
				}
			}

			&:hover {
				background: darken(white, 7.5%);
				transform: translateY(-2.5%) !important;
			}

			+ .quiz {
				margin-top: 6px;
			}

			.date {
				font-weight: 600;
				width: 70px;
			}

			> div {
				align-items: center;
				display: flex;
				margin-left: auto;

				@include mobile {
					flex-basis: calc(100% - 70px); // Subtract .date width
				}

				> div {
					width: 120px;

					&:last-of-type {
						width: 60px;
					}
				}
			}

			::v-deep .time {
				display: inline-flex;
			}

			.result {
				align-items: center;
				display: inline-flex;

				::v-deep .icon i {
					margin-right: 6px;
				}

				span {
					font-weight: 600;
				}
			}

			::v-deep .score {
				justify-content: space-between;
				width: 100%;
			}
		}
	}
}
</style>
